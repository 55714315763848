<template>
  <div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body v-if="!confirmStep">
        <b-row>
          <b-col cols="12" md="6" class="mb-2">
            <b-form-group label="Consultor de origem" label-for="oldConsultant">
              <v-select
                id="oldConsultant"
                v-model="oldConsultant"
                :reduce="(all_consultants) => all_consultants.id"
                :options="allConsultants"
                :loading="loading.allConsultants"
                label="name"
                @input="search"
                :class="getSelectErrorClass(v$.oldConsultant.$error)"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span v-if="v$.oldConsultant.required.$invalid">
                  Você deve informar um consultor de origem
                </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <b-form-group
              label="Consultor de destino"
              label-for="newConsultant"
            >
              <v-select
                id="newConsultant"
                v-model="newConsultant"
                :reduce="(consultant_list) => consultant_list.id"
                :options="consultants"
                :loading="loading.consultants"
                label="name"
                :class="getSelectErrorClass(v$.newConsultant.$error)"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span v-if="v$.newConsultant.required.$invalid">
                  Você deve informar um consultor de destino
                </span>
                <span v-else-if="v$.newConsultant.notEqual.$invalid">
                  O consultor de destino não pode ser o mesmo de origem
                </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <b-form-group
              label="Consultor de origem está na empresa"
              label-for="onlySubscriptions"
            >
              <v-select
                id="onlySubscriptions"
                v-model="transferOnlySubscriptions"
                :reduce="(options) => options.value"
                :options="assertBooleanOptions"
                label="name"
                :class="
                  getSelectErrorClass(v$.transferOnlySubscriptions.$error)
                "
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span v-if="v$.transferOnlySubscriptions.required.$invalid">
                  Você deve informar se o consultor de origem segue na empresa
                </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <b-form-group label="Clientes para transferência">
              <b-table
                hover
                class="position-relative"
                :items="customersList"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="Não foram encontrados clientes para transferência"
                :busy="loading.customers"
                @row-clicked="selectCustomer"
              >
                <template #head(selection)="data">
                  <b-form-checkbox
                    v-model="selectAllCustomers"
                    @input="selectAll"
                  />
                </template>

                <!-- Column: selection -->
                <template #cell(selection)="data">
                  <div class="text-wrap">
                    <b-form-checkbox
                      v-model="selectedCustomers"
                      :value="data.item.id"
                      @click.native.stop
                    />
                  </div>
                </template>

                <!-- Column: Name -->
                <template #cell(name)="data">
                  <div class="text-wrap">
                    <span class="align-text-top text-capitalize">{{
                      data.item.name
                    }}</span>
                  </div>
                </template>
              </b-table>
              <div
                class="invalid-feedback"
                style="display: block"
                v-if="v$.selectedCustomers.$error"
              >
                <span> Você deve selecionar ao menos um cliente </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center justify-content-end mb-2">
          <div class="mx-1" cols="12" md="6">
            <b-button variant="primary" @click="confirm">
              Transferir clientes
            </b-button>
          </div>
        </b-row>
      </b-card-body>
      <b-card-body v-else>
        <div class="text-sm-left font-italic text-danger m-2">
            Essa operação pode levar vários minutos para ser concluída. Você receberá um e-mail assim que a transferência for finalizada.
        </div>
        <b-row class="m-2">
          <b-col cols="12" md="6">
            <small class="text-muted"> Consultor de origem </small>
            <h5>{{ oldConsultantName }}</h5>
          </b-col>
          <b-col cols="12" md="6">
            <small class="text-muted"> Consultor de destino </small>
            <h5>{{ newConsultantName }}</h5>
          </b-col>
        </b-row>
        <b-row class="m-2">
          <b-col cols="12" md="6">
            <small class="text-muted">
              Consultor de origem está na empresa
            </small>
            <h5>{{ transferOnlySubscriptions | assertion }}</h5>
          </b-col>
        </b-row>
        <b-row class="mt-2 ml-2">
          <b-col cols="12">
            <div class="d-flex justify-content-start">
              <h5>Clientes</h5>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-2 ml-2">
          <b-col cols="12" md="6" class="d-inline-block">
            <template v-for="(customer, index) in customers">
              <div
                v-if="index < customers.length / 2"
                :key="`customer-${customer.id}`"
              >
                <span class="d-inline-block">{{ customer.name }}</span>
                <br /><br />
              </div>
            </template>
          </b-col>
          <b-col cols="12" md="6" class="d-inline-block">
            <template v-for="(customer, index) in customers">
              <div
                v-if="index >= customers.length / 2"
                :key="`customer-${customer.id}`"
              >
                <span class="d-inline-block">{{ customer.name }}</span>
                <br /><br />
              </div>
            </template>
          </b-col>
        </b-row>

        <b-row class="d-flex align-items-center justify-content-end m-2">
          <div class="mx-1" cols="12" md="6">
            <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="confirmStep = false"
              :disabled="saving"
            >
              Cancelar
            </b-button>
            <b-button variant="primary" @click="save" :disabled="saving">
              <b-spinner v-if="saving" small />
              <feather-icon v-else icon="SaveIcon" class="mr-50" />
              <span>
                {{ saving ? "Salvando..." : "Salvar" }}
              </span>
            </b-button>
          </div>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters, mapMutations } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { getVueSelectErrorClass } from "@/helpers/validators";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import { CUSTOMER } from "@/constants/customers_types";
import { CUSTOMERS_AND_CONTRACTS } from "@/constants/customer_transfer";
import { assertBooleanOptions } from "@/constants/assertions";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BFormCheckbox,
    vSelect,
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  data() {
    return {
      assertBooleanOptions,
      loading: {
        allConsultants: false,
        consultants: false,
        customers: false,
        options: false,
      },
      tableColumns: [
        { key: "selection", label: "", thStyle: "width: 50px" },
        { key: "name", label: "Nome", stickyColumn: true },
      ],
      confirmStep: false,
      saving: false,
      oldConsultant: undefined,
      newConsultant: undefined,
      selectAllCustomers: false,
      selectedCustomers: [],
      transferOnlySubscriptions: false,
    };
  },
  validations() {
    return {
      oldConsultant: { required },
      newConsultant: {
        required,
        notEqual: (consultant) => {
          return this.oldConsultant !== consultant;
        },
      },
      selectedCustomers: {
        required,
        minLength: minLength(1),
      },
      transferOnlySubscriptions: { required },
    };
  },
  computed: {
    ...mapGetters({
      customersList: sharedTypes.CUSTOMERS,
      allConsultants: sharedTypes.ALL_CONSULTANTS,
      consultants: sharedTypes.CONSULTANTS,
      customerTransferOptions: types.CUSTOMER_TRANSFER_OPTIONS,
    }),
    customers: function () {
      const customers = _.filter(this.customersList, (val) =>
        this.selectedCustomers.includes(val.id)
      );
      return customers;
    },
    oldConsultantName: function () {
      const consultant = _.find(
        this.allConsultants,
        (val) => this.oldConsultant == val.id
      );
      return consultant.name;
    },
    newConsultantName: function () {
      const consultant = _.find(
        this.consultants,
        (val) => this.newConsultant == val.id
      );
      return consultant.name;
    },
  },
  mounted() {
    this.search();
    this.loading.allConsultants = true;
    this.getAllConsultants()
      .catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.allConsultants = false;
      });
    this.loading.consultants = true;
    this.getConsultants()
      .catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultants = false;
      });
    this.loading.options = true;
  },
  methods: {
    ...mapActions({
      getCustomers: sharedTypes.GET_ALL_CUSTOMERS,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getAllConsultants: sharedTypes.GET_ALL_CONSULTANTS,
      transferCustomers: types.TRANSFER_CUSTOMERS,
    }),
    ...mapMutations({
      mutateCustomerList: sharedTypes.MUTATE_CUSTOMERS,
    }),
    search() {
      if (this.oldConsultant) {
        this.selectedCustomers = [];
        this.selectAllCustomers = false;
        this.loading.customers = true;
        this.getCustomers({
          customer_type: CUSTOMER,
          consultant_id: this.oldConsultant,
        })
          .catch((error) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao buscar os customers. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading.customers = false;
          });
      } else {
        this.mutateCustomerList([]);
      }
    },
    async confirm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.confirmStep = true;
    },
    async save() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      this.transferCustomers({
        old_consultant_id: this.oldConsultant,
        new_consultant_id: this.newConsultant,
        customers: this.selectedCustomers,
        only_subscriptions: this.transferOnlySubscriptions,
      })
        .then((resp) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Transferência solicitada com sucesso.",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao realizar a solicitação de transferência. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.clear();
          this.saving = false;
        });
    },
    selectAll() {
      if (this.selectAllCustomers) {
        this.selectedCustomers = _.map(this.customersList, "id");
      } else {
        this.selectedCustomers = [];
      }
    },
    selectCustomer(el) {
      if (!this.selectedCustomers.includes(el.id)) {
        this.selectedCustomers.push(el.id);
      } else {
        this.selectedCustomers = _.filter(this.selectedCustomers, (e) => {
          return e != el.id;
        });
      }
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    clear() {
      this.confirmStep = false;
      this.saving = false;
      this.oldConsultant = undefined;
      this.newConsultant = undefined;
      this.selectAllCustomers = false;
      this.selectedCustomers = [];
      this.mutateCustomerList([]);
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
